<template>
  <div class="bvt_partner_wrapper">
    <div class="bvt_partner_head">
      <h2>{{ $t("L_MAIN.PARTNER_0001") }}</h2>
      <p>{{ $t("L_MAIN.PARTNER_0002") }}</p>
    </div>
    <ul class="bvt_partner_main">
      <li v-for="(item, index) in partnerList" :key="index" :style="{marginRight: item.right + 'px'}">
        <img :style="{height: item.height + 'px'}" :src="item.imgURl" />
      </li>
    </ul>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/index/partner/t01.jpg");
let image2 = require("@/assets/image/pc/index/partner/t02.png");
let image3 = require("@/assets/image/pc/index/partner/t03.png");
let image4 = require("@/assets/image/pc/index/partner/t04.png");
let image5 = require("@/assets/image/pc/index/partner/t05.png");
let image6 = require("@/assets/image/pc/index/partner/t06.png");
let image7 = require("@/assets/image/pc/index/partner/t07.png");
let image8 = require("@/assets/image/pc/index/partner/t08.png");
let image9 = require("@/assets/image/pc/index/partner/t09.png");
let image10 = require("@/assets/image/pc/index/partner/t010.png");
let image11 = require("@/assets/image/pc/index/partner/t011.png");
let image12 = require("@/assets/image/pc/index/partner/t012.png");
let image13 = require("@/assets/image/pc/index/partner/t013.png");
let image14 = require("@/assets/image/pc/index/partner/t014.png");
let image15 = require("@/assets/image/pc/index/partner/t015.png");
export default {
  name: "partner",
  data() {
    return {
      partnerList: [
        {
          imgURl: image1,
          right: 48,
          height: 50
        },
        {
          imgURl: image2,
          right: 48,
          height: 50
        },
        {
          imgURl: image3,
          right: 50,
          height: 50
        },
        {
          imgURl: image4,
          right: 0,
          height: 50
        },
        {
          imgURl: image6,
          right: 122,
          height: 60
        },
        {
          imgURl: image7,
          right: 122,
          height: 60
        },
        {
          imgURl: image8,
          right: 114,
          height: 60
        },
        {
          imgURl: image5,
          right: 0,
          height: 60
        },
        {
          imgURl: image9,
          right: 122,
          height: 60
        },
        {
          imgURl: image10,
          right: 122,
          height: 50
        },
        {
          imgURl: image11,
          right: 113,
          height: 50
        },
        {
          imgURl: image12,
          right: 0,
          height: 50
        },
        {
          imgURl: image13,
          right: 122,
          height: 60
        },
        {
          imgURl: image14,
          right: 122,
          height: 50
        },
        {
          imgURl: image15,
          right: 122,
          height: 58
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/partner.scss";
</style>