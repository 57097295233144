<template>
  <div class="bvt_advantage_wrapper">
    <div v-for="(item, index) in advantageList" :key="index" class="bvt_advantage_main">
      <div class="bvt_advantage_img" v-if="index%2 === 0" :style="{height: height}">
        <img :src="item.imgURl" />
      </div>
      <div class="bvt_advantage_content" v-else :style="{height: height}">
        <div class="bvt_advantage_content_main">
          <h2>{{ item.title }}</h2>
          <p class="desc">{{ item.desc }}</p>
          <p v-for="(i, j) in item.details" :key="j" class="details_item">> {{ i.label }}</p>
        </div>
      </div>
      <div class="bvt_advantage_content" v-if="index%2 === 0" :style="{height: height}">
        <div class="bvt_advantage_content_main">
          <h2>{{ item.title }}</h2>
          <p class="desc">{{ item.desc }}</p>
          <p v-for="(i, j) in item.details" :key="j" class="details_item">> {{ i.label }}</p>
        </div>
      </div>
      <div class="bvt_advantage_img" v-else :style="{height: height}">
        <img :src="item.imgURl" />
      </div>
    </div>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/index/advantage/tu01.png");
let image2 = require("@/assets/image/pc/index/advantage/tu02.png");
let image3 = require("@/assets/image/pc/index/advantage/tu03.png");
export default {
  name: "advantage",
  data() {
    return {
      advantageList: [
        {
          imgURl: image1,
          title: this.$t("L_MAIN.PRODUCT_0005"),
          desc: this.$t("L_MAIN.PRODUCT_0006"),
          details: [
            {
              label: this.$t("L_MAIN.PRODUCT_0007"),
            },
            {
              label: this.$t("L_MAIN.PRODUCT_0008"),
            },
            {
              label: this.$t("L_MAIN.PRODUCT_0009"),
            }
          ]
        },
        {
          imgURl: image2,
          title: this.$t("L_MAIN.PRODUCT_0010"),
          desc: this.$t("L_MAIN.PRODUCT_0011"),
          details: [
            {
              label: this.$t("L_MAIN.PRODUCT_0012"),
            },
            {
              label: this.$t("L_MAIN.PRODUCT_0013"),
            },
            {
              label: this.$t("L_MAIN.PRODUCT_0014"),
            }
          ]
        },
        {
          imgURl: image3,
          title: this.$t("L_MAIN.PRODUCT_0015"),
          desc: this.$t("L_MAIN.PRODUCT_0016"),
          details: [
            {
              label: this.$t("L_MAIN.PRODUCT_0017"),
            },
            {
              label: this.$t("L_MAIN.PRODUCT_0018"),
            },
            {
              label: this.$t("L_MAIN.PRODUCT_0019"),
            }
          ]
        }
      ],
      height: document.body.clientWidth / 2 * 0.3806 + "px"
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.height = document.body.clientWidth / 2 * 0.3806 + "px";
      })();
    };
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/advantage.scss";
</style>