import request from '@/utils/request_fxpc';

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get',
  });
}
// 登录方法
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data: data,
  });
}

// 无验证码登录方法
export function loginNoCaptcha(data) {
  return request({
    url: '/loginNoCaptcha',
    method: 'post',
    data: data,
  });
}

