<template>
  <div class="bvt_serve_wrapper">
    <div class="bvt_serve_head">
      <h2>{{ $t("L_MAIN.SERVICE_0001") }}</h2>
      <p>{{ $t("L_MAIN.SERVICE_0002") }}</p>
    </div>
    <ul class="bvt_serve_main">
      <li v-for="(item, index) in serveList" :key="index">
        <div class="bvt_serve_img">
          <img :src="item.imgURl" alt="">
        </div>
        <div class="bvt_serve_title">
          {{ item.title }}
        </div>
        <div class="bvt_serve_content">
          {{ item.content }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/index/serve/fuwu_btn01.png");
let image2 = require("@/assets/image/pc/index/serve/fuwu_btn02.png");
let image3 = require("@/assets/image/pc/index/serve/fuwu_btn03.png");
export default {
  name: "serve",
  data() {
    return {
      serveList: [
        {
          imgURl: image1,
          title: this.$t("L_MAIN.SERVICE_0003"),
          content: this.$t("L_MAIN.SERVICE_0004")
        },
        {
          imgURl: image2,
          title: this.$t("L_MAIN.SERVICE_0005"),
          content: this.$t("L_MAIN.SERVICE_0006")
        },
        {
          imgURl: image3,
          title: this.$t("L_MAIN.SERVICE_0007"),
          content: this.$t("L_MAIN.SERVICE_0008")
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/serve.scss";
</style>