/*
 * @Author: yang shanshan
 * @Date: 2024-5-30
 * @Last Modified by: yang shanshan
 * @Last Modified time: 2024-5-30
 */
import projectConfig from '../../src/config/projectConfig.js'
import axios from 'axios'
import store from "../store";
import router from "@/router";
const service = axios.create({
  baseURL: projectConfig.buildingURL,
  timeout: 300000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

/****** respone拦截器==>对响应做处理 ******/
service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        //响应错误处理
        return Promise.reject(error);
    }
);
export default service;
