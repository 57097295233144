import request from "@/utils/request_building";
export function loginByPhone(phone) {
  return request({
    url: `sys/user/loginxByPhone?phone=${phone}`,
    method: 'post'
  })
}
export function loginByName(data) {
  return request({
    url: 'sys/user/loginx',
    method: 'post',
    data
  })
}
