<template>
  <div class="index-wrapper">
    <banner ref="banner"></banner>
    <product ref="product"></product>
    <advantage ref="advantage"></advantage>
    <kind ref="kind"></kind>
    <serve ref="serve"></serve>
    <partner ref="partner"></partner>
  </div>
</template>

<script>
import banner from "@/components/pc/index/banner.vue";
import product from "@/components/pc/index/product.vue";
import advantage from "@/components/pc/index/advantage.vue";
import kind from "@/components/pc/index/kind.vue";
import serve from "@/components/pc/index/serve.vue";
import partner from "@/components/pc/index/partner.vue";
export default {
  name: "index",
  components: {
    banner,
    product,
    advantage,
    kind,
    serve,
    partner,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/index.scss";
</style>
