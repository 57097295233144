<template>
  <div class="bvt_kind_wrapper">
    <div class="bvt_kind_main">
      <ul id="bvt_kind">
        <li v-for="(item, index) in kindList" :key="index" @mouseleave="mouseLeaveOne" @mouseover="mouseOverOne(item)">
          <img class="kind_img" :src="item.imgURl" />
          <div class="tip" :class="selected === item.id ? 'active':''">
            <div class="tip_icon">
              <img :src="item.tipImgURl" />
            </div>
            <div class="tip_title">
              {{ item.title }}
            </div>
            <div class="tip_des">
              {{ item.desc }}
            </div>
            <div class="tip_content">
              <p>{{ item.content }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let image1 = require("@/assets/image/pc/index/kind/youshi01.png");
let image2 = require("@/assets/image/pc/index/kind/youshi02.png");
let image3 = require("@/assets/image/pc/index/kind/youshi03.png");
let image4 = require("@/assets/image/pc/index/kind/youshi05.png");
let image5 = require("@/assets/image/pc/index/kind/youshi04.png");
let tipImage1 = require("@/assets/image/pc/index/kind/btn01.png");
let tipImage2 = require("@/assets/image/pc/index/kind/btn02.png");
let tipImage3 = require("@/assets/image/pc/index/kind/btn03.png");
let tipImage4 = require("@/assets/image/pc/index/kind/btn05.png");
let tipImage5 = require("@/assets/image/pc/index/kind/btn04.png");
export default {
  name: "kind",
  data() {
    return {
      kindList: [
        {
          id: 1,
          imgURl: image1,
          tipImgURl: tipImage1,
          title: this.$t("L_MAIN.KIND_0001"),
          desc: this.$t("L_MAIN.KIND_0002"),
          content: this.$t("L_MAIN.KIND_0003")
        },
        {
          id: 2,
          imgURl: image2,
          tipImgURl: tipImage2,
          title: this.$t("L_MAIN.KIND_0004"),
          desc: this.$t("L_MAIN.KIND_0005"),
          content: this.$t("L_MAIN.KIND_0006")
        },
        {
          id: 3,
          imgURl: image3,
          tipImgURl: tipImage3,
          title: this.$t("L_MAIN.KIND_0007"),
          desc: this.$t("L_MAIN.KIND_0008"),
          content: this.$t("L_MAIN.KIND_0009")
        },
        {
          id: 4,
          imgURl: image4,
          tipImgURl: tipImage4,
          title: this.$t("L_MAIN.KIND_0010"),
          desc: this.$t("L_MAIN.KIND_0011"),
          content: this.$t("L_MAIN.KIND_0012")
        },
        {
          id: 5,
          imgURl: image5,
          tipImgURl: tipImage5,
          title: this.$t("L_MAIN.KIND_0013"),
          desc: this.$t("L_MAIN.KIND_0014"),
          content: this.$t("L_MAIN.KIND_0015")
        }
      ],
      selected: null
    }
  },
  methods: {
    mouseOverOne(item) {
      this.selected = item.id;
    },
    // 鼠标移出
    mouseLeaveOne() {
      this.selected = null;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/index/kind.scss";
</style>